<template>
  <div class="grid admintext-demo">
    <div class="col-12">
      <div class="card">
    <DataTable :value="discussions" :lazy="true" :totalRecords="totalItems"
               @page="onPageDoc($event)" dataKey="id" :paginator="true" :rows="nbItems"
               :rowHover="true"
               class="p-datatable-gridlines table-filter-texte"
               responsiveLayout="scroll" :loading="loading">
      <template #loading>
        Loading data. Please wait.
      </template>
      <Column field="name_site" header="Site" style="width: 30rem;"></Column>

      <Column field="topic" :header="t('SubjectOfDiscussion')" style="width:10rem"
              :showFilterMatchModes="false">
        <template #body="{data}">
          <div class="tooltip"
               v-tooltip.right="'<p><strong>'+t('SubjectOfDiscussion')+ ':</strong>'+ data.topic+'</p>'">
            <p>{{ data.topic }}</p>
          </div>
        </template>
      </Column>

      <Column field="lastMessage" :header="t('lastMessage')" style="width:10rem"
              :showFilterMatchModes="false">
        <template #body="{data}">
          <div class="tooltip"
               v-tooltip.right="'<p><strong>'+t('lastMessage')+ ':</strong>'+ data.lastMessage+'</p>'">
            <p v-html="data.lastMessage"></p>
          </div>
        </template>
      </Column>
      <Column field="createdAt" :header="t('discussionLaunchedOn')" style="width: 20rem;"></Column>
      <Column field="lastUpdate" :header="t('dateOfLastMessage')" style="width: 10rem;"></Column>
      <Column field="nbMessages" :header="t('numberOfMessage')" style="width: 10rem;"></Column>
      <Column :headerStyle="{'min-width': '4rem', 'text-align': 'center'}" :bodyStyle="{'text-align': 'center', overflow: 'visible'}" :header="t('StatusReadUnread')">
        <template #body="{data}">
          <i v-if="data.is_hotline_readed === true" class="pi pi-check" style="color: slateblue"></i>
          <span v-else class="pi pi-times" style="color: red"></span>
        </template>
      </Column>
      <Column :headerStyle="{'min-width': '4rem', 'text-align': 'center'}" :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
        <template #body="{data}">
          <Button type="button" icon="pi pi-eye" @click="handleClickDiscussion(data)"></Button>
        </template>
      </Column>

      <template #empty>
        {{ t('NoDataToDisplay') }}
      </template>
    </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import HotlineService from "@/service/HotlineService";
import {usePrimeVue} from "primevue/config";
import {computed} from "vue";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";

export default {
  name: "Discussion",
  setup() {
    const store = useStore()
    const primevue = usePrimeVue();
    const t = primevue.config.StoreTranslation.t
    const user = computed(() => {
      return store.getters.currentUser
    });
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    return {primevue, t, user, id_site, store};
  },
  data() {
    return {
      nbItems: 10,
      loading: false,
      discussions: [],
      totalItems: 0,
      nbPage: 1,
    }
  },
  hotlineService: null,
  async mounted() {
    this.loading = true
    await this.getSites()
    await this.getDiscussions(0, this.nbItems);

  },
  created() {
    this.hotlineService = new HotlineService();
  },
  methods: {
    handleClickDiscussion(data) {
      if (this.user.is_hotline && data.is_hotline_readed === false) {
        let payload = {
          "is_hotline_readed": true
        }
        this.hotlineService.readMessage(data.last_message_id, payload)
      }
      this.$router.push({ path: '/ui-ax/chat/' + data.id, params: { id: data.id, topic: data.data } });

    },
    getDiscussions(page, nbItems)
    {
      this.loading = true;
      this.discussions = []
      // let payload = `?site=${this.id_site}&page=${page}&limit=${nbItems}`
      return this.hotlineService.getDiscussionList({customHeader: false, site: this.id_site, type: 'discussion', page:page, limit: nbItems}).then((data) => {
        this.loading = false;
        if (data.status === 200) {
          this.discussions = data.data.discussions
          this.totalItems = data.data.total_discussions
        }
      })
    },
    onPageDoc(event) {
      this.nbPage = event.page + 1
      this.getDiscussions(event.page, this.nbItems);
    },
    getSites() {
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        function addConcatName(arr, parentCode = null) {
          arr.forEach(obj => {
            const childSiteName = obj.site_name || '';
            if (parentCode) {
              obj.concat_name = `${parentCode}-${childSiteName}`;
            }
            if (!parentCode) {
              obj.concat_name = obj.name;
            }
            if (obj.childrens) {
              addConcatName(obj.childrens, obj.concat_name);
            }
          });
        }

        let ob = data;
        addConcatName(ob);
        let entity_name = ''
        var formatSelectTree = (obj, indexCh = null, parent) => {
          obj?.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF, element)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
            if (element.entity_type === 'CUSTOMER') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": element.name,
                }
              })
            } else if (element.entity_type === 'entity') {
              entity_name = entity_name + element.code
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": parent.name,
                }
              })
            } else {
              if (parent.site_name) {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": element.concat_name
                  }
                })
              } else {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": element.concat_name
                  }
                })
              }
              this.treeSelectNodes.push(obj[index].data)
            }
          });
          return obj;
        };
        formatSelectTree(ob);
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/demo/styles/badges.scss';
.tooltip.title-message {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
  margin-bottom: 0;
  padding-left: 8px;
}
</style>
